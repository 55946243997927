import React, { useEffect, useState,} from "react";
import SVG from "react-inlinesvg";
import project_1 from '../Assets/Images/proj_1.jpg'
import project_2 from '../Assets/Images/proj_2.jpg'
import project_3 from '../Assets/Images/proj_3.jpg'

import play_1 from '../Assets/Images/play1.svg'
import play_2 from '../Assets/Images/play2.svg'
import play_3 from '../Assets/Images/play3.svg'
import arrow_icon_1 from "../Assets/Images/arrow-1.svg"
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import { useNavigate } from 'react-router-dom';

const Portfolio = () => {
  const navigate = useNavigate();
  const {allPortfolio } = UserProfile()
  const [portfolioData,setPortfolioData]=useState([])
    useEffect(() => {
          if(allPortfolio===undefined || allPortfolio==='')
          {
            setPortfolioData([])
          }
          else{
            setPortfolioData(allPortfolio.data)
          }
          
      }, [allPortfolio])
    const gotoPortfolio=()=>{
      navigate('/portfolio')
    }
    return (
      <div className='Portfolio'>

      <div>
        <div className='Portfolio_header'>
          Our Projects
        </div>

        {/* <div className='Portfolio_text'>
          It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.
        </div> */}
      </div>


      <div className="portfolio_image_holder bk_img_portfolio portfolio_holder_mobile">
        {portfolioData.length>0 &&
          portfolioData.slice(0,1).map((data,index)=>(
            <div style={{ position: 'relative'}}key={index}>
              <SVG src={play_1} style={{ position: 'absolute', top: '-19px', left: '145px', zIndex: '999' }} />
              <div className="hexagon">
                <img src={Config.API_URL+data.image} />
              </div>
            </div>
          ))}
          {portfolioData.length>1 &&
          portfolioData.slice(1,2).map((data,index)=>(
            <div style={{ position: 'relative' }} key={index}>
              <SVG src={play_2} style={{ position: 'absolute', top: '152px', left: '119px', zIndex: '999' }} />
              <div className="hexagon2">
                <img src={Config.API_URL+data.image} />
              </div>
            </div>
          ))}
          {portfolioData.length>2 &&
          portfolioData.slice(2,3).map((data,index)=>(
            <div style={{ position: 'relative' }} key={index}>
              <SVG src={play_3} style={{ position: 'absolute', top: '298px', left: '206px', zIndex: '999' }} />
              <div className="hexagon3">
                <img src={Config.API_URL+data.image} />
              </div>
            </div>
          ))}


            <div className='mb-3 d-flex justify-content-center'>
              <button type="button" className="submit_button w35" 
              style={{ marginTop: '115%' }}
              onClick={gotoPortfolio}
              >
                <SVG src={arrow_icon_1} style={{ marginRight: '8%' }} />
                Show More
              </button>
            </div>
          
        
      </div>

      {/* <div className="portfolio_image_holder portfolio_holder_web"> */}
      <div className="gallery_card_holder_4 portfolio_holder_web">
      {portfolioData.length>0 &&
          portfolioData.slice(0,5).map((data,index)=>(
            // <div style={{ position: 'relative'}}key={index}>
            
            //   <div className="hexagon">
            //     <img src={Config.API_URL+data.image} style={{height:'265px',width:'330px'}}/>
            //   </div>
            // </div>
            <div className="gallary_card_4" key={index}>
                  <div className="gallary_card_image">
                      <img src={Config.API_URL+data.image} className="gallary_image"/>
                  </div>
                  
            </div>
          ))}
      </div>
      <div className='portfolio_show_holder_web'>
        <div className='mb-3 d-flex justify-content-center'>
                {/* <button type="button" className="submit_button" 
                style={{ marginTop: '1%',width:'10%',marginBottom:'3%' }}
                onClick={gotoPortfolio}
                >
                  <SVG src={arrow_icon_1} style={{ marginRight: '8%' }} />
                  Show More
                </button> */}

            <button type="button" className="btn submit_button mb-5 mt-5" 
                
                onClick={gotoPortfolio}
                >
                  <SVG src={arrow_icon_1}/>
                  <span className="pl-5">Show More</span>
                </button>

        </div>
      </div>
      

    </div>
    );
}

export default Portfolio;


