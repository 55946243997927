import React, { useEffect, useState, } from "react";
import SVG from "react-inlinesvg";
import ikoicon from "../Assets/Images/logo-iko_1.png"
import decra from "../Assets/Images/decra.png"
import decra_logo from "../Assets/Images/decra-logo-1.svg";
import logo_iko from "../Assets/Images/logo-iko.svg";
import Atlas_Korea_logo from "../Assets/Images/atlas_korea_logo.png";
import everest_logo from "../Assets/Images/everest-logo.png";
import gaf from "../Assets/Images/gaf_1.png"
import bitu from "../Assets/Images/nbitunill.png"
import downloadicon from "../Assets/Images/download.svg"
import chat from '../Assets/Images/chat.svg';
import call from '../Assets/Images/call.svg';
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import { Link,useNavigate } from 'react-router-dom';
import pdf_1 from "../Assets/pdf/Decra Brochure August 2015.pdf";
import pdf_2 from "../Assets/pdf/MR3L325_IKO_Dynasty_ArmourZone_Brochure_CAN_EN_REF.pdf";
import pdf_3 from "../Assets/pdf/Atlas Korea Brochure.pdf";
import pdf_4 from "../Assets/pdf/Everest Stone Coated Metal Roof Tile_compressed.pdf";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Products = () => {
  const responsive = {
    0: { items: 1 },
    280: { 
      items: 2,
      itemsFit: 'contain',
    },
    390 : { 
      items: 2,
      itemsFit: 'contain',
    },
    1536 : { items: 6 },
  };
  const navigate = useNavigate();
  
  const gotoQuote = () => {
    navigate('/contact')
  }
  const open1stPh=()=>{
    window.open('tel:9917373000');
}
  const open2ndPh=()=>{
      window.open('tel:9012301234');
  }
  return (
    <div className='homeMainDiv_Products'>
      <div className="homeMainDiv_Products_header"> Our Products </div>
      <div className="homeMainDiv_Products_headersub"> Build without boundaries with <span style={{ 'fontWeight': 'bold' }}>Ashbury </span></div>
      <div className="homeMainDiv_Products_headersub" style={{ 'fontWeight': 'bold' }}>  International </div>



      <div className="card_holder">

      <AliceCarousel
                  infinite
                  autoPlay
                  autoPlayInterval="5000"
                  controlsStrategy="alternate"
                  responsive={responsive}
                  disableDotsControls
                  //disableButtonsControls
      >
        <div className='card_view'>

          <div className='iko_div'>
            <SVG src={logo_iko} style={{width:'100%',height:'140px'}}/>
          </div>

          <div className='iko_div_text'>
            {/* <div className='homeMainDiv_products_card_header'> Iko Roofing Shingles</div> */}
            <div className='homeMainDiv_products_card_text'>
            The company was founded in 1951. 
            It first manufactured building paper and then 
            expanded to include coated roll roofing. 
            Its first asphalt shingle was made in 1954, 
            and the rest, as they say, is history.
            </div>
          </div>

          <Link to={pdf_2} target="_blank" download className="noCssLink">
            <button type='button' className='download_button w100'>
              <SVG src={downloadicon} className="download_icon" />
              Brochure
            </button>
          </Link>
          


        </div>




        <div className='card_view'>

          <div className='iko_div'>
            <SVG src={decra_logo} style={{width:'100%',height:'140px'}}/>
          </div>

          <div className='iko_div_text'>
            {/* <div className='homeMainDiv_products_card_header'> Decra Roofing System</div> */}
            <div className='homeMainDiv_products_card_text'>
            In 1957, AHI Roofing invented the World's First & Original 
            stone coated metal roof tiles. Since then, we have been growing 
            rapidly and become the world's largest pressed metal roof tile manufacturer.
            </div>
          </div>

          
          <Link to={pdf_1} target="_blank" download className="noCssLink">
            <button type='button' className='download_button w100'>
              <SVG src={downloadicon} className="download_icon" />
              Brochure
            </button>
            </Link>
          

        </div>

        <div className='card_view'>

          <div className='iko_div'>
            <img src={Atlas_Korea_logo} style={{width:'100%',height:'140px'}}/>
          </div>

          <div className='iko_div_text'>
            {/* <div className='homeMainDiv_products_card_header'> Iko Roofing Shingles</div> */}
            <div className='homeMainDiv_products_card_text'>
            Atlas Roof Solutions LLP is a brand of Stone coated metal roof tile, 
            we take the lead in the industry with our differentiated technology and experience since 1985.

            
            </div>
          </div>

          <Link to={pdf_3} target="_blank" download className="noCssLink">
            <button type='button' className='download_button w100'>
              <SVG src={downloadicon} className="download_icon" />
              Brochure
            </button>
          </Link>

        </div>

        <div className='card_view'>

          <div className='iko_div'>
            <img src={everest_logo} style={{width:'100%',height:'140px'}}/>
          </div>

          <div className='iko_div_text'>
            {/* <div className='homeMainDiv_products_card_header'> Iko Roofing Shingles</div> */}
            <div className='homeMainDiv_products_card_text'>Everest Stone Coated Metal Roof Tile use aluminium zinc
             plated steel sheet (also call galvalume steel and PPGL) as base,
             covered by natural stone chips and sticked by acrylic resin glue.
             
            </div>
          </div>

          <Link to={pdf_4} target="_blank" download className="noCssLink">
            <button type='button' className='download_button w100' >
              <SVG src={downloadicon} className="download_icon" />
              Brochure
            </button>
          </Link>
            
          

        </div>
        </AliceCarousel>
      </div>

      
      <div className='mb-3 mt-4 d-flex justify-content-center'>
              <div className="need_help_button_holder mb-3">
                <button
                  type="button"
                  className="call_btn bk_white"
                  onClick={open2ndPh}
                  >
                  <SVG src={call} style={{ marginRight: '5px' }} />
                  9012301234
                </button>
                  <button 
                    type="button"
                    onClick={open1stPh}
                    style={{
                      backgroundColor:'transparent',
                      color:'white',
                      border:'none',
                      fontWeight: '600'
                    }}
                  >
                    9917373000
                  </button>
              </div>

        </div>
                
      

    </div>

  );
}

export default Products;


