import React, { useEffect, useState, } from "react";
import SVG from "react-inlinesvg";
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import { useNavigate } from 'react-router-dom';
import Blog_image_1 from '../Assets/Images/Blog_image_1.png'
import arrow_icon from "../Assets/Images/arrow.svg"
import share_icon from "../Assets/Images/share.svg"

import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";
import Whatsapp from "../Layout/Whatsapp.js";
import { Helmet } from "react-helmet";

const BlogPage = () => {
    const { allBlog } = UserProfile()
    const [blogData, setBlogData] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (allBlog === undefined || allBlog === '') {
            setBlogData([])
        }
        else {
            setBlogData(allBlog.data)
        }
    }, [allBlog])
    

    const gotoBlogDetails = (data) => {
        console.log("BLOG ID",data);
        //navigate('/'+data.alias_name+'/', { state: { blog: data } });
        navigate('/blog-details/'+data.alias_name+'/');
    }



    return (
        <div className="ashbury-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ashbury International | Blog</title>
                <meta name="description" content="Discover Ashbury International" />
                <link rel="canonical" href={"http://www.ashburyinternational.com/blog"} ></link>
                <meta property="og:title" content="Ashbury International | Blog" />
                <meta property="og:description" content="Discover Ashbury International" />
                <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
                <meta property="og:url" content="http://www.ashburyinternational.com/blog" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            <div className="BlogMainDiv">{/*white*/}
                <Whatsapp/>
                <div className="BlogMainDiv_News_title">
                    Our Blogs
                </div>
                <div className='BlogMainDiv_News_subtitle'>
                    build without boundaries with <span style={{ fontWeight: '600' }}>Ashbury International</span>
                </div>



                <div className = 'blog_view'>
                    {blogData.map((data, index) => (
                        <div className='Blogcard' key={index}>


                            <div style={{ position: "relative" }}>
                                {/* <div className='blog_share_icon'>
                                    <img src={share_icon} />
                                </div> */}
                                <div className='Blog_image'>
                                    <img src={Config.API_URL+ data.image} className='Blog_image_property' />
                                </div>

                            </div>


                            <div className='Blogtextdiv'>
                                <div className='Blogcardtext_1'>
                                    {data.title}
                                </div>
                                <div className='Blogcardtext_2'>
                                    {data.postedon} |  {data.postedby}
                                </div>
                                <div className='Blogcardtext_3' dangerouslySetInnerHTML={{__html: data.description.substr(0, 150)}}>
                                    
                                </div>

                                <button type="button"
                                    className="know_more_button w39 mt_6 clGreen" 
                                    onClick={() => gotoBlogDetails(data)}
                                    style={{border:'none'}}
                                >
                                    <SVG src={arrow_icon} style={{ marginRight: '8%' }} />
                                    Know More
                                </button>

                                {/* <div className='Blogcardtext_4 mt_6'>
                            Category: Decra Roofing Shingles
                        </div> */}
                            </div>

                        </div>
                    ))}


                </div>

                <Office />


            </div>
            <Contact />
            <Footer />
        </div>
    )
}

export default BlogPage;