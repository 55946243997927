import React, { useEffect, useState, } from "react";
import group from "../Assets/Images/group_work.png";
import uttaranchal from '../Assets/Images/uttaranchal.png';
import forest from '../Assets/Images/forest.png';
import BSNL from '../Assets/Images/BSNL.png';

import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";
import Whatsapp from "../Layout/Whatsapp";
import Clients from "../Layout/Clients";
import { Helmet } from "react-helmet";
import Thankyou from "../Layout/Thankyou";





const ThankyouPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return (
        <div className="ashbury-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ashbury International | About Us</title>
                <meta name="description" content="Discover Ashbury International" />
                <link rel="canonical" href={"http://www.ashburyinternational.com/"} ></link>
                <meta property="og:title" content="Ashbury International | About Us" />
                <meta property="og:description" content="Discover Ashbury International" />
                <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
                <meta property="og:url" content="http://www.ashburyinternational.com/" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            <div className="aboutUsMainDiv">
                <Whatsapp/>
                <div className="aboutUs_Asbury_main">
                    

                        <Thankyou/>

                    
                </div>
                <div className="aboutUs_pinkdiv">
                   
                    
                    <Clients/>
                </div>
                <Office/>
                <Contact/>
                <Footer/>
            </div>
        </div>
    )
}

export default ThankyouPage;