// import './Css.css';
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import HomePage from './Pages/HomePage';
import AboutUsPage from './Pages/AboutUsPage';
import BlogPage from './Pages/BlogPage';
import BlogDetails from './Pages/BlogDetails';
import GallaryPage from './Pages/GallaryPage';
import ContactUsPage from './Pages/ContactUsPage';
import PortfolioPage from './Pages/PortfolioPage';
import ProductPage from './Pages/ProductPage';
import ProductListPage from './Pages/ProductListPage';
import ThankyouPage from './Pages/ThankyouPage';



const Navigation=()=> {
  return (
    <Router>
        <Routes>
            <Route exact path="/" Component={HomePage}/>
            <Route exact path="/about-us" Component={AboutUsPage}/>
            <Route exact path="/blog" Component={BlogPage}/>
            <Route exact path="/thank-you" Component={ThankyouPage}/>
            {/* <Route exact path="/blog-details" Component={BlogDetails}/> */}
            <Route exact path="/blog-details/:blogdetails/" Component={BlogDetails}/>
            <Route exact path="/gallary" Component={GallaryPage}/>
            <Route exact path="/portfolio" Component={PortfolioPage}/>
            <Route exact path="/contact" Component={ContactUsPage}/>
            <Route exact path="/product" Component={ProductPage}/>
            {/* <Route exact path="/product-list" Component={ProductListPage}/> */}
            <Route exact path="/:productList/" Component={ProductListPage}/>
        </Routes>
    </Router>
  );
}

export default Navigation;
