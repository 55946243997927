import React, {
  createContext,
  useState,
  useContext,
  useEffect
} from "react";
import Config from "../Config/Config.json"
import axios from "axios";
//import { useAuth } from "./AuthContext";
// import { useNavigation } from "@react-navigation/native";
//import  ActivityLoader  from "../Global/ActivityLoader";
//"API_URL": "http://115.187.43.48:6542/",
const UserContext = createContext();

const UserProvider = ({ children}) => {
//const { authToken,authData,logOut } = useAuth();
//console.log("USER CONTEXT 18===>",authData);
const [allGallery, setAllGallery] = useState('')
const [allBlog, setAllBlog] = useState('')
const [allPortfolio, setAllPortfolio] = useState('')
const [allProduct, setallProduct] = useState('')
const [allCategory,setallCategory] = useState('')


  useEffect(() => {
    
    getAllGallery();
    getAllBlog();
    getAllPortfolio();
    getAllProduct();
    getAllCategory();
    
  },[]);

  //=====================GALLERY API================//

  const getAllGallery = async () => {
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_GALLERY, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      console.log("GET RESP GALLERY",response);
      setAllGallery(response.data);
      // console.log("allgalleryoutput",allGallery);
      
    }
    catch(error){
      console.log("Gallery context ERROR",error)
    }
  }
  
  //===================BLOG API===================//

  
  const getAllBlog = async () => {
    // console.log("url",Config.API_URL + Config.GET_ALL_BLOG)
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_BLOG, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      console.log("GET RESP BLOG",response);
      setAllBlog(response.data);
      // console.log("allgalleryoutput",allGallery);
      
    }
    catch(error){
      console.log("blog context ERROR",error)
    }
  }

  const getAllBlogById = async (id) => {
    try{
      const response = await axios.get(Config.API_URL + Config.BLOG_BY_ID+id, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      console.log("GET RESP BLOG",response);
      return response.data
    }
    catch(error){
      console.log("blog context ERROR",error)
    }
  }
    

  //=====================PORTFOLIO API================//

  const getAllPortfolio = async () => {
    try{
      const response = await axios.get(Config.API_URL + Config.GET_ALL_PORTFOLIO, 
        {
          headers: {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+authToken
          },
          
      })
      console.log("GET RESP PORTFOLIO",response);
      setAllPortfolio(response.data);
      // console.log("allgalleryoutput",allGallery);
      
    }
    catch(error){
      console.log("portfolio context ERROR",error)
    }
  }
  
  
  
//=====================PRODUCTS API================//

const getAllProduct = async () => {
  try{
    const response = await axios.get(Config.API_URL + Config.GET_ALL_PRODUCT, 
      {
        headers: {
          'Content-Type': 'application/json',
          //'Authorization':'Bearer '+authToken
        },
        
    })
    console.log("GET RESP PRODUCT",response);
    setallProduct(response.data);
    // console.log("allgalleryoutput",allGallery);
    
  }
  catch(error){
    console.log("product context ERROR",error)
  }
}

const getAllCategory = async () => {
  try{
    const cat_response = await axios.get(Config.API_URL + Config.GET_ALL_CATEGORY, 
      {
        headers: {
          'Content-Type': 'application/json',
          //'Authorization':'Bearer '+authToken
        },
        
    })
    console.log("GET RESP CATEGORY",cat_response);
    setallCategory(cat_response.data);
    
  }
  catch(error){
    console.log("category context ERROR",error)
  }
}

const getAllProductByCategory = async (catid) => {
  try{
    const response = await axios.get(Config.API_URL + Config.GET_PRODUCT_BY_CATEGORY+catid, 
      {
        headers: {
          'Content-Type': 'application/json',
          //'Authorization':'Bearer '+authToken
        },
        
    })
    console.log("GET RESP PRODUCT BY CAT",response);
    return response.data
    
  }
  catch(error){
    console.log("product context ERROR",error)
  }
}

// =======================QUOTES API========================= //

const addQuote = async (args) => {
  const response = await axios.post(Config.API_URL + Config.QUOTE_ADD, args, 
    {
      headers: {
        'Content-Type': 'application/json',
      },
      
  })
  return response.data
}








  return (
    <UserContext.Provider
      value={{
        allGallery,
        allBlog,
        getAllBlog,
        getAllBlogById,
        allPortfolio,
        getAllPortfolio,
        allProduct,
        getAllProduct,
        getAllProductByCategory,
        allCategory,
        getAllCategory,
        addQuote
        
      }}
    >
      {children}
      {/* <ActivityLoader isLoaderShow ={loaderOn}/> */}
    </UserContext.Provider>
  )
}
function UserProfile() {
  const context = useContext(UserContext)

  // if (!context) {
  //   throw new Error('userProfile must be used within an userProvider')
  // }

  return context
}
export { UserContext, UserProvider, UserProfile }


