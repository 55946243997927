import React, { useEffect, useState,} from "react";
import SVG from "react-inlinesvg";
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import call from '../Assets/Images/call.svg';
import DownArrow from "../Assets/Images/down_arrow.svg";
import GallaryImage from '../Assets/Images/gallary_img.png';
import Whatsapp from "../Layout/Whatsapp.js";
import Office from "../Layout/Office.js";
import Support from "../Layout/Support.js";
import { Helmet } from "react-helmet";



const GallaryPage = () => {
    const { allGallery} = UserProfile()
    const [galleryData,setGalleryData]=useState([])

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    useEffect(() => {
    
        if(allGallery===undefined || allGallery==='')
        {
          setGalleryData([])
        }
        else{
          setGalleryData(allGallery.data)
        }
    }, [allGallery])

    return (
        <div className="ashbury-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Best roofing shingles Design</title>
                <meta name="description" content="Choose from the best roofing shingles design. Enhance the look of your roof by choosing best Asphalt roof shingles" />
                <link rel="canonical" href={"http://www.ashburyinternational.com/gallary"} ></link>
                <meta property="og:title" content="Best roofing shingles Design" />
                <meta property="og:description" content="Choose from the best roofing shingles design. Enhance the look of your roof by choosing best Asphalt roof shingles." />
                <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
                <meta property="og:url" content="http://www.ashburyinternational.com/gallary" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            <div className="gallary_pinkdiv">
                <Whatsapp/>
                <div className="gallary_whitediv">
                    <div className="BlogMainDiv_News_title mt_neg">
                        Our Gallery
                    </div>
                    <div className='BlogMainDiv_News_subtitle'>
                        build without boundaries with <span style={{ fontWeight: '600' }}>Ashbury International</span>
                    </div>
                    <div className="gallery_card_holder">
                    {galleryData.map((data, index) => (  
                    <div className="gallary_card" key={index}>
                        <div className="gallary_card_image">
                            <img src={Config.API_URL+data.image} className="gallary_image"/>
                        </div>
                        <div className="gallary_card_text">
                            {data.title}
                        </div>
                    </div>
                    ))}
                    </div>
                </div>
                <Support/>
                {/* <div className="Support_header mt10"> Keep In Touch </div>
                <div className="Support_headersub m_r_l"> It’s Easy, We Are Just A Call Away </div>
                <div className='mb-3 mt-4 d-flex justify-content-center'>
                    <div className="need_help_button_holder">
                        <button
                            type="button"
                            className="call_btn bk_white">
                            <SVG src={call} style={{ marginRight: '5px' }} />
                            9012 301 234
                        </button>
                        9917 373 000
                    </div>

                </div> */}

            </div>
            <Office/>
            
            <Footer />
        </div>
    )
}

export default GallaryPage;