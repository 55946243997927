import React, { useEffect, useState,} from "react";
import cl1 from '../Assets/Images/clients/logo2.jpg'
import cl2 from '../Assets/Images/clients/logo3.jpg';
import cl3 from '../Assets/Images/clients/logo5.jpg';
import cl4 from '../Assets/Images/clients/logo10.jpg';
import cl5 from '../Assets/Images/clients/logo11.jpg';
import cl6 from '../Assets/Images/clients/logo12.jpg';
import cl7 from '../Assets/Images/clients/logo13.jpg';
import cl8 from '../Assets/Images/clients/logo14.jpg';
import cl9 from '../Assets/Images/clients/logo15.jpg';
import cl10 from '../Assets/Images/clients/logo16.jpg';
import cl11 from '../Assets/Images/clients/logo16.png';
import cl12 from '../Assets/Images/clients/logo17.jpg';
import cl13 from '../Assets/Images/clients/logo17.png';

import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Clients = () => {
  const responsive = {
    0: { items: 1 },
    280: { 
      items: 2,
    },
    390 : { 
      items: 2,
    },
    550:{
      items: 3,
    },
    768:{
      items: 3,
    },
    880:{
      items: 4,
    },
    1024:{
      items: 4,
    },
    1100:{
      items: 5,
    },
    1920:{
      items: 6,
    },
    1536 : { items: 6 },
  };
    return (
      <div >
            <div className='clients'>
              <div className='clients_header'>
                Our Clients
              </div>
              <div className='clients_text'>
                Our happy customers
              </div>
            </div>

            <div className='clients_images'>

              <AliceCarousel
                  infinite
                  autoPlay
                  autoPlayInterval="3000"
                  controlsStrategy="alternate"
                  responsive={responsive}
                  disableDotsControls
                  disableButtonsControls
                >
                        <div className='BSNL'>
                          <img src={cl1} style={{width:'90%'}}/>
                        </div>

                        <div className='Uttaranchal'>
                          <img src={cl2} style={{width:'90%'}}/>
                        </div>

                        <div className='Forest'>
                          <img src={cl3} style={{width:'90%'}}/>
                        </div>

                        <div className='BSNL'>
                          <img src={cl4} style={{width:'90%'}}/>
                        </div>

                        <div className='Uttaranchal'>
                          <img src={cl5} style={{width:'90%'}}/>
                        </div>

                        <div className='Forest'>
                          <img src={cl6} style={{width:'90%'}}/>
                        </div>

                        <div className='BSNL'>
                          <img src={cl7} style={{width:'90%'}}/>
                        </div>

                        <div className='Uttaranchal'>
                          <img src={cl8} style={{width:'90%'}}/>
                        </div>

                        <div className='Forest'>
                          <img src={cl9} style={{width:'90%'}}/>
                        </div>
                        <div className='BSNL'>
                          <img src={cl10} style={{width:'90%'}}/>
                        </div>

                        <div className='Uttaranchal'>
                          <img src={cl11} style={{width:'90%'}}/>
                        </div>

                        <div className='Forest'>
                          <img src={cl12} style={{width:'90%'}}/>
                        </div>
                        <div className='Forest'>
                          <img src={cl13} style={{width:'90%'}}/>
                        </div>
                </AliceCarousel>

              

            </div>
          </div>
        
    );
}

export default Clients;


