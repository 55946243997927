import SVG from "react-inlinesvg";
import ashburylogo from '../Assets/Images/logo-94x99.svg';
import message_icon from '../Assets/Images/Message.svg';
import fb from '../Assets/Images/fb_icon.png';
import insta from '../Assets/Images/insta_icon.png';
import { Link } from "react-router-dom";




const Footer = () => {
    const gotoFb=()=>{
        window.location.replace('https://www.facebook.com/AshburyInternational/')
    }
    const gotoInsta=()=>{
        window.location.replace('https://www.instagram.com/dreamroofs_india/?igshid=MzRlODBiNWFlZA%3D%3D')
    }
    const openMailto=(value)=>{
        window.location.replace('mailto:'+value)
    }
    return (
        <div className='Footer'>

            <div className='mt-4 d-flex justify-content-center'>
                <div className='ashburyLogo'>
                    <img src={ashburylogo} className="logo_width" />
                </div>
            </div>

            <div className='Footer_text'>
                <span style={{ 'fontWeight': '600' }}>Ashbury International was founded in February 2012 </span>
                and is headquartered in Mumbai, Maharashtra, India. 
                We have our business operations across India with our 
                corporate office in Mumbai.Also our Warehouse in Mumbai, Kochi & Dehradun
            </div>

            <div className='anchor_web'>

                <div>
                    <Link to="/" className="footerLink">Home </Link>
                </div>
                <div>
                    <Link to="/about-us" className="footerLink">About US</Link>
                </div>
                {/* <div>
                    <a> Products</a>
                </div> */}
                <div>
                    <Link to='/portfolio' className="footerLink">Portfolio</Link>
                </div>


                <div>
                    <Link to='/gallary' className="footerLink">Gallery </Link>
                </div>
                <div>
                    <Link to='/blog' className="footerLink">Blog </Link>
                </div>


            </div>

            <div className='anchor_mobile'>
                <div className='Navigation'>
                    <div>
                        <Link to="/" className="footerLink">Home </Link>
                    </div>
                    <div>
                        <Link to="/about-us" className="footerLink">About US</Link>
                    </div>
                    
                    <div>
                        <Link to='/portfolio' className="footerLink">Portfolio</Link>
                    </div>

                </div>

                <div className='Navigation_Gallery_blog'>
                    <div className='Gallery'>
                        <Link to='/gallary' className="footerLink">Gallery </Link>
                    </div>
                    <div>
                        <Link to='/blog' className="footerLink">Blog </Link>
                    </div>

                </div>
            </div>

            

            

            <div className='Email'>
                <div className='webview_email' style={{ textAlign: 'left' }}>
                    {/* <div className='mb-3'>
                        <button type="button"
                            className="footer_email"
                            onClick={()=>openMailto('sales@ashburyinternational.com')}
                        >
                            <SVG src={message_icon} style={{ marginRight: "10px" }} />
                            sales@ashburyinternational.com</button>
                    </div> */}
                    <div className='mb-3'>
                        <button type="button"
                            className="footer_email"
                            onClick={()=>openMailto('akstalwar@gmail.com')}
                        >
                            <SVG src={message_icon} style={{ marginRight: "10px" }} />
                            akstalwar@gmail.com</button>
                    </div>
                    <div className='mb-3'>
                        <button type="button"
                            className="footer_email"
                            onClick={()=>openMailto('akshay.ashbury@gmail.com')}
                        >
                            <SVG src={message_icon} style={{ marginRight: "10px" }} />
                            akshay.ashbury@gmail.com</button>

                    </div>
                </div>



            </div>


            <div className='copyrightDiv'>

                <div className='footer_text_1'>2023 All rights reserved</div>
                <div className='footer_fb d-flex justify-content-between align-items-center' >
                    <img src={fb} 
                        onClick={gotoFb}
                        style={{
                            cursor:'pointer',
                            height:'38px',
                            width:'38px'
                        }}
                    />
                    <img src={insta} 
                        style={{marginLeft:'10%',height:'30px',width:'30px',cursor:'pointer'}}
                        onClick={gotoInsta}
                    />
                </div>
                

            </div>

        </div>
    )
}

export default Footer;