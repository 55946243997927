import React, { useEffect, useState, } from "react";
import SVG from "react-inlinesvg";
import menu from '../Assets/Images/menu.svg';
import menu_expand from '../Assets/Images/menu_expand.svg';
import call from '../Assets/Images/call.svg';
import down from '../Assets/Images/arrow-down.svg';
import ashburylogo from '../Assets/Images/logo-94x99.svg';
import { Link, useNavigate } from "react-router-dom";
import { UserProfile } from "../Context/UserContext.js";


const Header = () => {
    const navigate = useNavigate();
    const { allCategory } = UserProfile()
    const [drawerStat, setDrawerStat] = useState(false)
    const [submneuDrawer, setsubmneuDrawer] = useState(false)
    const [categoryData, setCategoryData] = useState([])

    const openDrawer = () => {
        setDrawerStat(!drawerStat)
    }
    const openSubMenu = () => {
        setsubmneuDrawer(!submneuDrawer)
    }
    useEffect(() => {
        if (allCategory === undefined || allCategory === '') {
            setCategoryData([])
        }
        else {
            setCategoryData(allCategory.data)
        }

    }, [allCategory])
    const gotoProduct = (catData,alias) => {
        setDrawerStat(false)
        setsubmneuDrawer(false)
        //navigate('/product-list?category='+tempName, { state: { catData: catData } });
        if(alias==='Everest')
        {
            window.open('http://ashburyinternational.com/static/media/Everest Stone Coated Metal Roof Tile_compressed.8c13ae032c49bb1e104b.pdf','_blank')
            //Everest Stone Coated Metal Roof Tile_compressed.8c13ae032c49bb1e104b.pdf
            //Atlas%20Korea%20Brochure.d410ee9b998f68b993b0.pdf
        }
        else if(alias==='Atlas'){
            window.open('http://ashburyinternational.com/static/media/Atlas%20Korea%20Brochure.d410ee9b998f68b993b0.pdf','_blank')
        }
        else{
            navigate('/'+alias+'/');
        }
        
    }
    const open1stPh=()=>{
        window.open('tel:9917373000');
    }
    const open2ndPh=()=>{
        window.open('tel:9012301234');
    }
    return (
        <div className='headerDiv'>
            <div className='headerDiv_menucall'>
                
                {/* <div className="menu_number_holder">
                    <SVG src={call} />
                    <span>
                         9917 373 000
                    </span>
                </div> */}
                <div className="mobile_menu_number_holder">
                    <div className='headerDiv_menuBtn' onClick={openDrawer}>
                        {drawerStat === false ? (
                            <SVG src={menu} />
                        ) : (
                            <SVG src={menu_expand} />
                        )}



                    </div>
                    <button className='hederDiv_callBtn' onClick={open1stPh}>
                        <SVG src={call} /> 9917373000
                    </button>
                </div>
                <div className="mobile_logo_holder">
                    <div className='ashburyLogo'>
                        <SVG src={ashburylogo} className="logo_width"/>
                    </div>
                </div>
                <div className="mobile_right_button_holder">
                    <button className='hederDiv_callBtn_right' onClick={open2ndPh}>
                        <SVG src={call} /> 9012301234
                    </button>
                </div>
                {/* <div className="menu_number_holder">
                    <SVG src={call} />
                    <span>
                         9917 373 000
                    </span>
                </div> */}
            </div>


            {drawerStat === true &&
                <div id="myDropdown" className="dropdown-content">
                    <div className="menuHolder">
                        <Link to="/" className="noCssLink">
                            <div className="menuLink">Home</div>
                        </Link>
                        <div>

                            <div className="menuLink" onClick={openSubMenu}>
                                Products
                                <SVG src={down} />
                            </div>
                            {submneuDrawer === true &&
                                <div className="subMenuHolder">
                                    {categoryData.map((data, index) => (
                                        <div className="submenulink" key={index} onClick={() => gotoProduct(data,data.category_alias)}>{data.name}</div>
                                    ))}
                                </div>
                            }

                        </div>


                        <Link to="/about-us" className="noCssLink">
                            <div className="menuLink">About Us</div>
                        </Link>

                        <Link to='/blog' className="noCssLink">
                            <div className="menuLink">Blog</div>
                        </Link>
                        <Link to='/gallary' className="noCssLink">
                            <div className="menuLink">Gallery</div>
                        </Link>
                        <Link to='/portfolio' className="noCssLink">
                            <div className="menuLink">Projects</div>
                        </Link>

                        <Link to='/contact' className="noCssLink">
                            <div className="menuLink">Contact Us</div>
                        </Link>
                    </div>
                </div>
            }

            <div className="bar_menu">
                <div className="menu_holder_bar_col_md_4">
                    <Link to="/" className="noCssLink">
                        <div className="menuLink">Home</div>
                    </Link>

                    <Link to="/about-us" className="noCssLink">
                        <div className="menuLink">About Us</div>
                    </Link>

                    <Link to='/blog' className="noCssLink">
                        <div className="menuLink">Blog</div>
                    </Link>
                    <Link to='/gallary' className="noCssLink">
                        <div className="menuLink">Gallery</div>
                    </Link>
                    <Link to='/portfolio' className="noCssLink">
                        <div className="menuLink">Projects</div>
                        
                    </Link>
                </div>
                <div className='ashburyLogo'>
                    <img src={ashburylogo} className="logo_width"/>
                </div>
                <div className="menu_holder_bar_col_md_4">
                <div style={{position:'relative'}}>
                    <div className="menuLink" style={{cursor:'pointer'}} onClick={openSubMenu}>Products <SVG src={down} fill="black"/></div>
                    {submneuDrawer === true &&
                        <div className="subMenuHolder_web">
                            {categoryData.map((data, index) => (
                                <div className="submenulink_web" key={index} onClick={() => gotoProduct(data,data.category_alias)}>{data.name}</div>
                            ))}
                        </div>
                    }
                    
                </div>

                <Link to='/contact' className="noCssLink">
                    <div className="menuLink">Contact Us</div>
                </Link>

                <div>
                    <button className='hederDiv_callBtn' onClick={open1stPh}>
                        <SVG src={call} /> 9917373000
                    </button>
                </div>

                <div>
                    <button className='hederDiv_callBtn' onClick={open2ndPh}>
                        <SVG src={call} /> 9012301234
                    </button>
                </div>
            </div>
            </div>

        </div >
    );
}

export default Header;


