// import './App.css';
import './Css/style.css';

// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";


import Navigation from './AppRouter';
import { UserProvider } from './Context/UserContext';






const App=()=> {
  return (
    <UserProvider>
        <Navigation/>
    </UserProvider>
    
  );
}

export default App;
