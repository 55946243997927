import React, { useEffect, useState,} from "react";
import SVG from "react-inlinesvg";
import call from '../Assets/Images/call.svg';
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";

const Support = () => {

  const open1stPh=()=>{
    window.open('tel:9917373000');
  }
  const open2ndPh=()=>{
      window.open('tel:9012301234');
  }
  
    return (
      <div className='support'>
            <div className="Support_header"> Need support? </div>
            <div className="Support_headersub"><span style={{ 'fontWeight': 'bold' }}> Contact us anytime,</span> we're just a call away! </div>
            <div className='mb-3 mt-4 d-flex justify-content-center'>
              <div className="need_help_button_holder">
                <button
                  type="button"
                  className="call_btn bk_white"
                  onClick={open2ndPh}
                  >
                  <SVG src={call} style={{ marginRight: '5px' }} />
                  9012301234
                </button>
                <button 
                    type="button"
                    onClick={open1stPh}
                    style={{
                      backgroundColor:'transparent',
                      color:'white',
                      border:'none',
                      fontWeight: '600'
                    }}
                  >
                    9917373000
                  </button>
              </div>

            </div>
          </div>
        
    );
}

export default Support;


