import React, { useEffect, useState, } from "react";
import group from "../Assets/Images/group_work.png";
import uttaranchal from '../Assets/Images/uttaranchal.png';
import forest from '../Assets/Images/forest.png';
import BSNL from '../Assets/Images/BSNL.png';

import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";
import Whatsapp from "../Layout/Whatsapp";
import Clients from "../Layout/Clients";
import { Helmet } from "react-helmet";





const AboutUsPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    
    return (
        <div className="ashbury-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Supplier of Roofing Shingles India</title>
                <meta name="description" content="Ashbury International is the importer and supplier of Asphalt Roofing Shingles in India. Get best roofing shingles for your roof" />
                <link rel="canonical" href={"http://www.ashburyinternational.com/"} ></link>
                <meta property="og:title" content="Supplier of Roofing Shingles India" />
                <meta property="og:description" content="Ashbury International is the importer and supplier of Asphalt Roofing Shingles in India. Get best roofing shingles for your roof" />
                <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
                <meta property="og:url" content="http://www.ashburyinternational.com/" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            <div className="aboutUsMainDiv">
                <Whatsapp/>
                <div className="aboutUs_Asbury_main">
                    <div className='Ashbury_international'>

                        {/* <div className='group_div'>
                            <img src={group} />
                        </div> */}

                        <div className='text'>
                            <div className='Ashbury_international_title'>
                                Discover Ashbury International
                            </div>

                            <div className='Ashbury_international_subtitle'>
                                Unveiling Our Story
                            </div>

                            <div className='Ashbury_international_txt_1'>
                                At Ashbury International, we understand the importance of reliable and durable
                                 roofing materials that not only protect your property but also enhance its aesthetic appeal. 
                                 With years of experience in the industry, we have established ourselves as trusted 
                                 importers of high-quality roof shingles and stone-coated metal roofs.
                            </div>

                            <div className='Ashbury_international_txt_2'>
                                Our commitment to excellence extends beyond our product offerings. 
                                We pride ourselves on providing exceptional customer service, 
                                working closely with our clients to ensure their satisfaction at every step of the process. 
                                Whether you are a contractor,architect, builder, or homeowner, 
                                we have the expertise and resources to support your roofing projects effectively.
                            </div>

                            <div className='Ashbury_international_subtitle'>
                                Here are some key features of our services:
                            </div>

                            <div className='Ashbury_international_txt_1 text-start'>
                                <b>1. Extensive Product Range: </b>
                                We offer a wide selection of roof shingles and stone-coated metal roofs, 
                                available in various colors, styles, and materials. 
                                Our inventory includes options suitable for residential, 
                                commercial, and industrial applications.
                            </div>

                            <div className='Ashbury_international_txt_1 text-start'>
                                <b>2. Premium Quality: </b> We prioritize quality and only partner with reputable manufacturers to 
                                source our roofing materials. This ensures that our products are durable, 
                                weather-resistant, and built to last, providing you with long-term value.
                            </div>

                            <div className='Ashbury_international_txt_1 text-start'>
                                <b>3. Competitive Pricing: </b>We understand the importance of cost-effective solutions. 
                                By leveraging our strong relationships with suppliers, 
                                we strive to offer competitive prices without compromising on quality.
                            </div>

                            <div className='Ashbury_international_txt_1 text-start'>
                                <b>4. Expert Guidance: </b>
                                Our knowledgeable team is dedicated to assisting you in choosing the 
                                right roofing materials for your specific needs. 
                                We provide expert advice, product recommendations, and technical support throughout your project.
                            </div>

                            <div className='Ashbury_international_txt_1 text-start'>
                                <b>5. Timely Delivery: </b>We prioritize efficient logistics and aim to deliver your roofing 
                                materials promptly. We understand the importance of meeting project timelines and strive to be a 
                                reliable partner in your roofing endeavors.
                            </div>

                            

                            <div className='Ashbury_international_txt_1 italic text-start'>
                                We would be delighted to discuss your roofing requirements in more detail and 
                                explore how Ashbury International can assist you. Please feel free to reach out to 
                                us at <b>9197373000</b> or <b>9012301234</b> to schedule a meeting or request additional information.
                            </div>

                            <div className='Ashbury_international_txt_1 italic text-start'>
                                To learn more about our company and the services we offer, 
                                please visit our website at <b>www.ashburyinternational.com </b>
                            </div>

                            <div className='Ashbury_international_txt_1 italic'>
                                Thank you for considering Ashbury International as your roofing solution provider. 
                                We look forward to the opportunity to serve you and contribute to the success of your upcoming 
                                roofing projects.
                            </div>

                            

                            {/* <div className='Ashbury_international_txt_footer'>
                                As Decra metal roofing is one of the most durable roofing systems in the industry, people have started choosing
                            </div> */}



                        </div>

                    </div>
                </div>
                <div className="aboutUs_pinkdiv">
                    {/* <div className="aboutUs_bluediv pb20">
                        <div className="founding">
                            <div className="founding_title">
                                Founding Partners
                            </div>
                            <div className="founding_txt_1">
                                Mr Ankush Talwar
                            </div>
                            <div className="founding_txt_2">
                                (Finance, hrd, logistics and planning)
                            </div>
                            <div className="founding_txt_1">
                                Mr Akshay Talwar
                            </div>
                            <div className="founding_txt_2">
                                (Marketing, sales and promotion)
                            </div>
                            <div className="dash dash-15"></div>
                            <div className="branch">
                                Branches In
                            </div>
                            <div className="branch_sub_txt">
                                Maharashtra, North East India , Punjab, Uttrakhand, New Delhi, Goa, Kochi, Gujarat & Bangalore.
                            </div>
                        </div>
                        <div className="ourVision">
                            Our Vision & Mission
                        </div>
                        <div className="ourVision_sub_1">
                            What we seek to become & Why we are here
                        </div>
                        <div className="ourVision_sub_2">
                            Our vision is to be viewed as the best supplier for construction products in the country.
                            We satisfy our customers by delivering results through prompt &quality services of supply and installation
                            of our products. Our desire to grow drives our passion to win in the marketplace. With a unified, low-cost operating structure,
                            we’ll remain competitive across every business and in every geographic region.
                        </div>
                        <div className="vision_card">
                            <div className="vision_card_header">
                                Our Vision & Mission
                            </div>
                            <div className="vision_card_txt_1">
                                We act with integrity and honesty.
                            </div>
                            <div className="dash dash-5"></div>
                            <div className="dash_1"></div>
                            <div className="vision_card_txt_1">
                                We recognize each person for the difference he or she makes.
                            </div>
                            <div className="dash dash-5"></div>
                            <div className="dash_1"></div>
                            <div className="vision_card_txt_1">
                                We drive innovation & results by understanding the market and its opportunities.
                            </div>
                            <div className="dash dash-5"></div>
                            <div className="dash_1"></div>
                            <div className="vision_card_txt_1">
                                We are committed to the values of responsibility, sustainability and transparency.
                            </div>
                            <div className="dash dash-5"></div>
                            <div className="dash_1"></div>
                            <div className="vision_card_txt_1">
                                We create safe and health-conscious work environments, require compliance and embrace environmental stewardship.
                            </div>
                        </div> 

                         <div className="vision_card mt10">
                            <div className="vision_card_header">
                                Our Operating Principles
                            </div>
                            <div className="vision_card_txt_1">
                                We assess the impact on customers and society when making decisions.
                            </div>
                            <div className="dash dash-5"></div>
                            <div className="dash_1"></div>
                            <div className="vision_card_txt_1">
                                We are externally focused. Our businesses are defined by markets.
                            </div>
                            <div className="dash dash-5"></div>
                            <div className="dash_1"></div>
                            <div className="vision_card_txt_1">
                                We are united by our common vision, mission, values and operating principles.
                            </div>
                            <div className="dash dash-5"></div>
                            <div className="dash_1"></div>
                            <div className="vision_card_txt_1">
                                We are committed to the values of responsibility, sustainability and transparency.
                            </div>
                        </div>

                    </div> */}
                    
                    <Clients/>
                </div>
                <Office/>
                <Contact/>
                <Footer/>
            </div>
        </div>
    )
}

export default AboutUsPage;