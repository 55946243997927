import React, { useEffect, useState,} from "react";
import SVG from "react-inlinesvg";
import arrow_icon from "../Assets/Images/arrow.svg"
import group from "../Assets/Images/group_work.png"
import about_us from "../Assets/Images/about.jpg"
import { useNavigate } from 'react-router-dom';
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";

const Thankyou = () => {
  
  
    return (
      <div className='Ashbury_international'>

            <div className='group_div about_w50'>
              <img src={about_us} className="aboutus_img"/>
            </div>

            <div className='text about_w50'>
              <div className='Ashbury_international_title'>
                Thank you
              </div>

              

              <div className='Ashbury_international_txt_1'>
                Thank you for reaching out.<br/>
                Talk to you soon,<br/>
                Thank you once again,<br/>
                Ashbury International
              </div>

              

              
              

            </div>

          </div>
        
    );
}

export default Thankyou;


