import React, { useEffect, useState,} from "react";
import SVG from "react-inlinesvg";
import call from '../Assets/Images/call.svg';
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import map_loc from '../Assets/Images/map_crop.jpeg';
import map_long from '../Assets/Images/map.png';


const Office = () => {

  const open2ndPh=()=>{
    window.open('tel:9012301234');
}
  
    return (
      <div className='office'>
            <div className='office_header'>
              Office locations
            </div>
            <div className='office_text'>
              Branches in <span style={{ 'fontWeight': 'bold' }}>Maharashtra, North East India , Punjab Uttarakhand, New Delhi, Goa, Gujarat, Kochi & Bangalore</span>
            </div>

            <div className='card_list'>
            
              <div className='Office_card '>
                <div className='Office_card_header mt-3'>
                  West Office
                </div>
                <div className='Office_card_body'>
                  3A Mandar, 2nd Floor, Mandar premises co-op society Ltd,Plot No. 193, TPS111, Bandra West 400050
                </div>
                <div className='mt-1 mb-1'>
                  <button className='hederDiv_callBtn' onClick={open2ndPh}>
                    <SVG src={call} /> 9012301234
                  </button>
                </div>
              </div>

              <div className='Office_card'>
                <div className='Office_card_header mt-3'>
                  North Office
                </div>
                <div className='Office_card_body'>
                  4-Curzon Road, Dalanwala, Dehradun 248001, Uttarakhand
                </div>
                <div className='mt-1 mb-1'>
                  <button className='hederDiv_callBtn' onClick={open2ndPh}>
                    <SVG src={call} /> 9012301234
                  </button>
                </div>
              </div>

              <div className='Office_card'>
                <div className='Office_card_header mt-3'>
                  South Office
                </div>
                <div className='Office_card_body'>
                    18/173 G, survey no 73/8B-5 KODUNGALLUR GRAMPANCHYAT EDAYAR
                    Binanipuram road,
                    City : ELOOR EDAYAR 
                    Pincode : 683502
                </div>
                <div className='mt-1 mb-1'>
                  <button className='hederDiv_callBtn' onClick={open2ndPh}>
                    <SVG src={call} /> 9012301234
                  </button>
                </div>
              </div>

              
              
            </div >

              <div style={{margin:'5%',border:'5px solid #c5c5c5',padding:'1%',borderRadius:'20px'}}>
                <img src={map_long} className="location_map_width"/>
              </div>
              
          </div>
        
    );
}

export default Office;


