import React, { useEffect, useState, } from "react";
import SVG from "react-inlinesvg";
import image_1 from '../Assets/Images/bgimage.png';
import slider_1 from '../Assets/Images/iko-slider1.jpg'
import slider_2 from '../Assets/Images/ashbury-slider2.jpg'
import chat from '../Assets/Images/chat.svg';
import whatsapp_1 from '../Assets/Images/whatsapp_1.png';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import { useNavigate } from 'react-router-dom';

const Slider = () => {
  const navigate = useNavigate();
  const { allProduct,allGallery } = UserProfile()
  const [sliderData, setSliderData] = useState([])
  useEffect(() => {
    if (allGallery === undefined || allProduct === '') {
      setSliderData([])
    }
    else {
      setSliderData(allGallery.data)
    }

  }, [allGallery])

  // const gotoWa = () => {
  //   window.location.replace('https://api.whatsapp.com/send/?phone=919012301234&text=Hi%2C+I+am+visiting+your+website+www.ashburyinternational.com&type=phone_number&app_absent=0');
  // }
  const gotoQuote = () => {
    navigate('/contact')
  }
  return (
    <div>
    <div className="slider_mobile">
    {sliderData.length > 0 ? (
      <AliceCarousel
        autoHeight
        infinite
        autoPlay
        //autoPlayInterval="3000"
        animationType="fadeout"
        animationDuration={2000}
        disableDotsControls
        disableButtonsControls
      //autoPlayDirection="rtl"
      >
        {sliderData.map((data, index) => (
          <div className="homeMainDiv_Text" key={index}>
            <div className="sliderW40">
                <div className="homeMainDiv_Text_BuildStandard">Ashbury International</div>
                <div className="homeMainDiv_Text_Ashbury">The Build Roof Standard</div>
              {/* <div className="homeMainDiv_Text_BuildStandard standard">{data.description}</div> */}
            </div>

            <div className="homeMainDiv_Image">
              <img src={Config.API_URL + data.image} className="img_slider_style" />
              {/* <div className="homeMainDiv_Image_Text">
                Lets <span style={{ 'fontWeight': '600' }}>Improve</span> your Roof
              </div> */}

              <div className="homeMainDiv_Image_Button">

                <button
                  type='buttton'
                  className='homeMainDiv_Image_Button_Quote'
                  onClick={gotoQuote}
                >Get a Quote
                  <SVG src={chat} style={{ marginLeft: '10px' }} />
                </button>


                {/* <button type='button' className="whatsapp_button" onClick={gotoWa}>
                  <img src={whatsapp_1} />
                </button> */}

              </div>

            </div>
          </div>
        ))}



      </AliceCarousel>
    ) 
    : (
      <AliceCarousel
        autoHeight
        infinite
        autoPlay
        animationType="fadeout"
        animationDuration={2000}
        disableDotsControls
        disableButtonsControls
      >
        <div className="homeMainDiv_Text">
          <div className="homeMainDiv_Text_BuildStandard">Ashbury International</div>
          <div className="homeMainDiv_Text_Ashbury">The Build Roof Standard</div>
          {/* <div className="homeMainDiv_Text_BuildStandard standard">{data.description}</div> */}


          <div className="homeMainDiv_Image">
            <img src={image_1} className="img_slider_style" />
            {/* <div className="homeMainDiv_Image_Text">
            Lets <span style={{ 'fontWeight': '600' }}>Improve</span> your Roof
          </div> */}
            <div className="homeMainDiv_Image_Button">

              <button
                type='buttton'
                className='homeMainDiv_Image_Button_Quote'
                onClick={gotoQuote}
                >Get a Quote
                <SVG src={chat} style={{ marginLeft: '10px' }} />
              </button>


              {/* <button type='button' className="whatsapp_button">
                <img src={whatsapp_1} />
              </button> */}

            </div>
          </div>
        </div>
      </AliceCarousel>
      
    )}
    </div>
    <div className="slider_web">
    <AliceCarousel
        autoHeight
        infinite
        autoPlay
        animationType="fadeout"
        animationDuration={1000}
        disableDotsControls
        disableButtonsControls
      >
        <div style={{ 
          backgroundImage: `url(${slider_1})` ,
          //backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height:'500px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '15%',
        }}>
          {/* <img src={slider_1} style={{height:'500px',width:'100%'}}/> */}
          <div
            style={{
              fontSize:'32px',
              color:'#fff',
              fontWeight:'bold'
            }}
          >Ashbury International</div>
          <div
          style={{
            fontSize:'20px',
            color:'#fff',
            fontWeight:'800'
          }}
          >The Build Roof Standard</div>
          <button
                type='buttton'
                className='homeMainDiv_Image_Button_Quote mt-5'
                onClick={gotoQuote}
                >Get a Quote
                <SVG src={chat} style={{ marginLeft: '10px' }} />
          </button>
        </div>

        <div style={{ 
          backgroundImage: `url(${slider_2})` ,
          //backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          height:'500px',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          padding: '15%',
        }}>
          {/* <img src={slider_1} style={{height:'500px',width:'100%'}}/> */}
          <div
            style={{
              fontSize:'32px',
              color:'#fff',
              fontWeight:'bold'
            }}
          >Ashbury International</div>
          <div
          style={{
            fontSize:'20px',
            color:'#fff',
            fontWeight:'800'
          }}
          >The Build Roof Standard</div>
          <button
                type='buttton'
                className='homeMainDiv_Image_Button_Quote mt-5' 
                onClick={gotoQuote}
                >Get a Quote
                <SVG src={chat} style={{ marginLeft: '10px' }} />
          </button>
        </div>
        
      </AliceCarousel>
    </div>
    </div>
  );
}

export default Slider;


