import React, { useEffect, useState,} from "react";
import SVG from "react-inlinesvg";
import arrow_icon from "../Assets/Images/arrow.svg"
import group from "../Assets/Images/group_work.png"
import about_us from "../Assets/Images/about.jpg"
import { useNavigate } from 'react-router-dom';
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";

const About = () => {
  const navigate = useNavigate();
  const gotoAboutUs = () => {
    navigate('/about-us');
  }
    return (
      <div className='Ashbury_international'>

            <div className='group_div about_w50'>
              <img src={about_us} className="aboutus_img"/>
            </div>

            <div className='text about_w50'>
              <div className='Ashbury_international_title'>
                Discover Ashbury International
              </div>

              <div className='Ashbury_international_subtitle'>
                Unveiling Our Story
              </div>

              <div className='Ashbury_international_txt_1'>
                  At Ashbury International, we understand the importance of reliable and durable 
                  roofing materials that not only protect your property but also enhance its aesthetic appeal. 
                  With years of experience in the industry, we have established ourselves as trusted importers of 
                  high-quality roof shingles and stone-coated metal roofs.
              </div>

              {/* <div className='Ashbury_international_txt_2'>
                It primarily Imports Roof Shingles from IKO, a US – 
                Canada based construction material manufacturer headquartered 
                in Canada.It also Imports Roof Shingles from GAF Material Corporation 
                (Largest manufacturer of Roof Shingles), a US based construction material manufacturer 
                headquartered in New Jersey.
              </div> */}

              {/* <div className='Ashbury_international_txt_footer'>
                As Decra metal roofing is one of the most durable roofing systems in the industry, 
                people have started choosing
              </div> */}

              
              {/* <button type="button"
                className="know_more_button about_w35 mt-5 about_mb_10 clGreen about_ml_35" onClick={gotoAboutUs}>
                <SVG src={arrow_icon} style={{ marginRight: '8%' }} />
                Know More
              </button> */}
              <button type="button"
                className="btn know_more_button mt-5 about_mb_10 clGreen" onClick={gotoAboutUs}>
                <SVG src={arrow_icon}/>
                <span className="pl-5">Know More</span>
              </button>

            </div>

          </div>
        
    );
}

export default About;


