import {useEffect} from "react";
import {useLocation } from 'react-router-dom';
import {UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import SVG from "react-inlinesvg";
import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import Quote from "../Layout/Quote.js";
import Support from "../Layout/Support.js";
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";

import IKO from '../Assets/Images/IKO.png'
import arrow_icon_1 from "../Assets/Images/arrow-1.svg"
import Whatsapp from "../Layout/Whatsapp.js";

const ProductPage = () => {
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (

        <div className="ashbury-container">
            <Header />

            <div className='product-blue'>
                <Whatsapp/>
                <div className='product-white'>

                    <div className='product-text-div'>
                        <div>
                            <img src={Config.API_URL +location.state.prData.image} style={{width:'100%'}}/>
                        </div>

                        <div className='product-title mt5'>
                            {location.state.prData.name}
                        </div>

                        {/* <div className='product-subtitle mt5 fw500'>
                            Ashbury International is an importer of IKO roofing shingles in India.
                        </div> */}

                        <div className='product-text mt5 fw400'>
                            {location.state.prData.description}
                        </div>

                        {/* <div className='product-text mt5'>
                            We offer a plethora of products under the umbrella term IKO roofing shingles, each product comes with its unique features and we don’t compromise on quality. We will assist 100% with installation and will send in certified personnel only who have years of experience in installing roofing shingles. Our price is highly competitive and you can rely on us when it comes to looks and beauty of the roofing shingles.
                        </div>

                        <div className='product-text mt5 product_text_style fw500'>
                            Ashbury International is just a call away and for us our consumers are the number one priority. We deliver fast and effective work with best quality personalized service.
                        </div>

                        <div className='product-text mt5 product_text_style fw500 text-color'>
                            Get in touch with us today if you want to know more about IKO roofing shingles.
                        </div> */}
                    </div>

                    <Quote title='Inquiry Form' />

                    <div className='reason'>
                        <div className="reason_txt_div">
                            <div className="reason_header"> Top Reasons to Choose Us </div>
                            <div className="reason_headersub">What Makes Us the <span style={{ 'fontWeight': 'bold' }}>Best Option</span> for Your</div>
                        </div>

                        <div className="product-card_holder">

                            <div className='reason_card_1 bk_img_1'>
                                <div className='product-reason_content_txt'>
                                    Certified Personnel
                                </div>
                            </div>


                            <div className='reason_card_1 bk_img_1'>
                                <div className='product-reason_content_txt'>
                                    Certified Personnel
                                </div>
                            </div>

                            <div className='reason_card_1 bk_img_1'>
                                <div className='product-reason_content_txt'>
                                    Certified Personnel
                                </div>
                            </div>


                            <div className='reason_card_1 bk_img_1'>
                                <div className='product-reason_content_txt'>
                                    Certified Personnel
                                </div>
                            </div>

                        </div>

                        <div className='mb-3 d-flex justify-content-center'>
                            <button type="button" className="submit_button w45" style={{ marginTop: '10%',marginBottom:'15%', width: '35%' }}>
                                <SVG src={arrow_icon_1} style={{ marginRight: '8%' }} />
                                Show More
                            </button>
                        </div>

                    </div>
                </div>
                <Support />
            </div>

            <Office />
            <Contact />
            <Footer />
        </div>
    )
}


export default ProductPage;