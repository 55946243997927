import React, { useEffect, useState,} from "react";
import whatsapp_1 from '../Assets/Images/whatsapp_1.png';
import Config from "../Config/Config.json";
import SVG from "react-inlinesvg";

import star_icon from "../Assets/Images/star.svg"
import best_price from "../Assets/Images/best_price.svg"
import personalized from "../Assets/Images/Personalized.svg"
import think from "../Assets/Images/think.svg"
import We_think from "../Assets/Images/We_think.svg"
import years from "../Assets/Images/years.svg"
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';

const Chooseus = () => {

  const responsive = {
    0: { items: 1 },
    280: { 
      items: 2,
    },
    390 : { 
      items: 2,
    },
    550:{
      items: 3,
    },
    768:{
      items: 3,
    },
    880:{
      items: 4,
    },
    1024:{
      items: 4,
    },
    1100:{
      items: 5,
    },
    1920:{
      items: 6,
    },
    1536 : { items: 6 },
  };
  

    return (
      <div className='reason'>
            <div className="reason_txt_div">
              <div className="reason_header"> Top Reasons to Choose Us </div>
              <div className="reason_headersub">What Makes Us the <span style={{ 'fontWeight': 'bold' }}>Best Option</span> for Your</div>
            </div>

            <div className="card_holder">

            <AliceCarousel
                  infinite
                  autoPlay
                  autoPlayInterval="1000"
                  controlsStrategy="alternate"
                  responsive={responsive}
                  disableDotsControls
                  disableButtonsControls
            >
              <div className='reason_card_1 bk_img_1'>
                <div className='reason_content'>
                  <div>
                    <SVG src={star_icon}></SVG>
                  </div>
                  <div className='reason_content_txt'>
                    Certified Personnel
                  </div>
                </div>
              </div>

              <div className='reason_card_1 bk_img_2'>
                <div className='reason_content'>
                  <div>
                    <SVG src={best_price}></SVG>
                  </div>
                  <div className='reason_content_txt'>
                    Best Price Guarntee
                  </div>
                </div>
              </div>



              <div className='reason_card_1 bk_img_3'>
                <div className='reason_content'>
                  <div>
                    <SVG src={We_think}></SVG>
                  </div>
                  <div className='reason_content_txt'>
                    We Think <br />About Consumer
                  </div>
                </div>
              </div>



              <div className='reason_card_1 bk_img_4'>
                <div className='reason_content'>
                  <div>
                    <SVG src={personalized}></SVG>
                  </div>
                  <div className='reason_content_txt'>
                    Personalised Service
                  </div>
                </div>
              </div>



              <div className='reason_card_1 bk_img_5'>
                <div className='reason_content'>
                  <div>
                    <SVG src={years}></SVG>
                  </div>
                  <div className='reason_content_txt'>
                    Years Of Experience
                  </div>
                </div>
              </div>



              <div className='reason_card_1 bk_img_6'>
                <div className='reason_content'>
                  <div>
                    <SVG src={think}></SVG>
                  </div>
                  <div className='reason_content_txt'>
                    Certified Personnel
                  </div>
                </div>
              </div>
              </AliceCarousel>
            </div>
            
          </div>
        
    );
}

export default Chooseus;


