import React, { useEffect, useState,useMemo,useCallback} from "react";
import SVG from "react-inlinesvg";
import { UserProfile } from "../Context/UserContext.js";
import { useLocation,useNavigate } from 'react-router-dom';
import Config from "../Config/Config.json";
import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import call from '../Assets/Images/call.svg';
import DownArrow from "../Assets/Images/down_arrow.svg";
import GallaryImage from '../Assets/Images/gallary_img.png';
import arrow_icon from "../Assets/Images/arrow.svg"
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";
import Whatsapp from "../Layout/Whatsapp.js";
import Support from "../Layout/Support.js";
import { Helmet } from "react-helmet";
import Quote from "../Layout/Quote.js";



const ProductListPage = () => {
    const navigate = useNavigate();
    const { getAllProductByCategory } = UserProfile()
    const location = useLocation();
    const [productData, setProductData] = useState([])
    const [categoryName, setCategoryName] = useState('')
    const [categoryDesc, setCategoryDesc] = useState('')
    const [categoryTitle, setCategoryTitle] = useState('')
    const [categoryPageDesc, setCategoryPageDesc] = useState('')
    useEffect(() => {
        window.scrollTo(0, 0)
        let tempAlias=window.location.pathname;
        let replaceSlash=tempAlias.split("/");
        getProductList(replaceSlash[1]);      
      }, [window.location.pathname])

    // useEffect(() => {
        
        
    
    //   }, [location.state.catData.id])

      

      const getProductList=async(alias)=>{
        const result=await getAllProductByCategory(alias)
        if(result===undefined || result==='')
        {
            setProductData([])
            setCategoryName('')
            setCategoryDesc('')
            setCategoryTitle('')
            setCategoryPageDesc('')
        }
        else{
            setProductData(result.data)
            // if(result.data.lenght>0){
                setCategoryName(result.data[0].category.name)
                setCategoryDesc(result.data[0].category.description)
                setCategoryTitle(result.data[0].category.page_title)
                setCategoryPageDesc(result.data[0].category.page_desc)
            // }
            // else{
            //     setCategoryName('')
            //     setCategoryDesc('')
            // }
            
        }
        
      }

    // const getProductList = useCallback(async () => {
    //     const result=await getAllProductByCategory(location.state.catData.id)
    //     setProductData(result.data)
    //     console.log("RESULT",result);
    //   }, [location.state.catData.id]);

    
      
      const gotoProductDetails=(data)=>{
        navigate('/product-page', {state: { prData: data } });
      }
    return (
        <div className="ashbury-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{categoryTitle}</title>
                <meta name="description" content={categoryPageDesc} />
                <link rel="canonical" href={window.location.href} ></link>
                <meta property="og:title" content={categoryTitle}/>
                <meta property="og:description" content={categoryPageDesc} />
                <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            <div className="gallary_pinkdiv">
                <Whatsapp/>
                <div className="gallary_whitediv">
                    <div className="BlogMainDiv_News_title mt_neg">
                        {categoryName}
                    </div>
                    <div className='BlogMainDiv_News_subtitle'>
                        {categoryDesc}
                    </div>
                    <div className="gallery_card_holder">
                    {productData.map((data, index) => (
                    <div className="gallary_card" key={index}>
                        <div className="gallary_card_image">
                            <img src={Config.API_URL + data.image}  className="gallary_image"></img>
                        </div>
                        <div className="gallary_card_text">
                        {data.name}
                        </div>

                        {/* <button type="button"
                            className="know_more_button mt_6 clGreen w39"
                            onClick={()=>gotoProductDetails(data)}
                        >
                            <SVG src={arrow_icon} style={{ marginRight: '8%' }} />
                            Know More
                        </button> */}
                    </div>
                    ))}
                    </div>
                    {/* <div className='mb-1 d-flex justify-content-center'>
                        <button type="button" className="submit_button w45" style={{ marginTop: '15%', width: '35%' }}>
                            <SVG src={DownArrow} style={{ marginRight: '8%' }} />
                            Show More
                        </button>
                    </div> */}
                </div>

                {/* <Support/> */}

            </div>
            <Quote/>
            <Office/>
            <Contact/>
            <Footer />
        </div>
    )
}

export default ProductListPage;