import React, { useEffect, useState,} from "react";
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import { useLocation } from 'react-router-dom';

import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import Quote from "../Layout/Quote.js";

import share_icon from "../Assets/Images/share.svg"
import facebook from "../Assets/Images/facebook.svg"
import whatsapp_1 from '../Assets/Images/whatsapp_1.png';
import Blog_image_1 from "../Assets/Images/Blog_image_1.png"
import call from '../Assets/Images/call.svg';
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";
import Whatsapp from "../Layout/Whatsapp.js";
import Support from "../Layout/Support.js";
import { Helmet } from "react-helmet";




const BlogDetails = () => {
    const location = useLocation();
    const { getAllBlogById } = UserProfile()
    const [blogData, setBlogData] = useState('')
    const [blogTitle, setBlogTitle] = useState('')
    const [blogDesc, setBlogDesc] = useState('')
    useEffect(() => {
        let tempAlias=window.location.pathname;
        let replaceSlash=tempAlias.split("/");
        console.log("BLOG DETAILS",replaceSlash)
        getBlogDetails(replaceSlash[2]);   
        window.scrollTo(0, 0)
      }, [])
      
    const getBlogDetails=async(alias)=>{
        const resp= await getAllBlogById(alias)
        if(resp!==undefined || resp.data.length>0){
            setBlogData(resp.data[0])
            setBlogTitle(resp.data[0].page_title)
            setBlogDesc(resp.data[0].page_desc)
        }
        else{
            setBlogData('')
            setBlogTitle('')
            setBlogDesc('')
        }
        console.log('blog details',resp)
    }
    
    return (
        <div className="blogdetail_ashbury-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>{blogTitle}</title>
                <meta name="description" content={blogDesc} />
                <link rel="canonical" href={window.location.href} ></link>
                <meta property="og:title" content={blogTitle}/>
                <meta property="og:description" content={blogDesc} />
                <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            <div className="BlogMainDiv">
                <Whatsapp/>
                <div className="BlogMainDiv_News_title">
                    Our Blog
                </div>
                <div className='BlogMainDiv_News_subtitle'>
                    build without boundaries with <span style={{ fontWeight: '600' }}>Ashbury International</span>
                </div>

                <div className='Blogcard_detail'>


                    <div style={{ position: "relative" }}>
                        {/* <div className='blog_share_icon'>
                            <img src={share_icon} />
                        </div> */}
                        <div className='Blog_image'>
                            <img src={Config.API_URL + blogData.image} className='Blog_image_property'/>
                        </div>

                    </div>


                    <div className='Blogtextdiv'>
                        <div className='Blogcardtext_title'>
                        {blogData.title}
                        </div>
                        <div className='Blogcardtext_2 fw'>
                            {blogData.postedon} |  {blogData.postedby}
                        </div>
                        <div className='Blogcardtext_3 fw' 
                            dangerouslySetInnerHTML={{__html: blogData.description}}
                        >
                            
                        </div>

                        

                        

                        
                        
                        
                        {/* <div className='Blogcardtext_title mt10'>
                            Share with friends
                        </div> */}

                        {/* <div className='Blog-detail-social'>
                            <div>
                              <img src={facebook}/>
                            </div>
                            <div>
                                <button type='button' className="whatsapp_button ml_40">
                                    <img src={whatsapp_1} />
                                </button>
                            </div> 
                        </div>  */}
                    </div>



                </div>
                
                {/* <Quote/> */}

                <Office/>
            </div>

            <Contact/>

            <Footer/>

        </div>
    )
}

export default BlogDetails;