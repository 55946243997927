import React, { useEffect, useState, } from "react";
import { UserProfile } from "../Context/UserContext.js";



import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import Slider from "../Layout/Slider.js";
import OurBlogs from "../Layout/OurBlog.js";
import Portfolio from "../Layout/Portfolio.js";
import Products from "../Layout/Products.js";
import About from "../Layout/About.js";
import Support from "../Layout/Support.js";
import Clients from "../Layout/Clients.js";
import Quote from "../Layout/Quote.js";
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";
import Whatsapp from "../Layout/Whatsapp.js";
import Chooseus from "../Layout/Chooseus.js";
import { Helmet } from "react-helmet";


const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {

  }, [])

  

  return (
    <div className="ashbury-container">
      <Helmet>
            <meta charSet="utf-8" />
            <title>Roofing Shingles in India</title>
            <meta name="description" content="Best Roofing Shingles in India for your roof. Importer of IKO & DECRA Stone coated metal roofing Shingles." />
            <link rel="canonical" href={"http://www.ashburyinternational.com/"} ></link>
            <meta property="og:title" content="Roofing Shingles in India" />
            <meta property="og:description" content="Best Roofing Shingles in India for your roof. Importer of IKO & DECRA Stone coated metal roofing Shingles." />
            <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
            <meta property="og:url" content="http://www.ashburyinternational.com/" />
            <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <div className="homeMainDiv">
        <Slider />
        <Whatsapp/>
        <Products />
        <Quote title="Get A Quote" />
        <div className='Asbury_main'>
          <About />
          
          <Support />




          <Chooseus/>
          <Portfolio />
          {/* <OurBlogs /> */}

          <Clients />
        </div>



        <div>


          <Office />

          <Contact />

          <Footer />

        </div>





      </div>


    </div>
  );
}

export default HomePage;