import { useEffect} from "react";
import SVG from "react-inlinesvg";
import Header from "../Layout/Header.js";
import Footer from "../Layout/Footer.js";
import Office from "../Layout/Office.js";
import Contact from "../Layout/Contact.js";

import call from '../Assets/Images/call.svg';
import Quote from "../Layout/Quote.js";
import Whatsapp from "../Layout/Whatsapp.js";
import Support from "../Layout/Support.js";
import { Helmet } from "react-helmet";

const ContactUsPage = () => {


    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


    return (
        <div className="ashbury-container">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ashbury International | Contact</title>
                <meta name="description" content="Discover Ashbury International" />
                <link rel="canonical" href={"http://www.ashburyinternational.com/contact"} ></link>
                <meta property="og:title" content="Ashbury International | Contact" />
                <meta property="og:description" content="Discover Ashbury International" />
                <meta property="og:image" content="http://www.ashburyinternational.com/static/media/ashbury-slider2.779d30593aa9da5f4acc.jpg" />
                <meta property="og:url" content="http://www.ashburyinternational.com/contact" />
                <meta property="og:type" content="website" />
            </Helmet>
            <Header />
            <div className="gallary_pinkdiv">
                <Whatsapp/>
                    <Quote/>
                
                {/* <div className="gallary_whitediv contact_pb">
                    <div className="BlogMainDiv_News_title mt_neg">
                        Get in Touch
                    </div>
                    <div className='BlogMainDiv_News_subtitle'>
                        Fill the form below and we will reply with a custom Quote for your needs
                    </div>
                    <div className='form_field_class'>
                        <form>
                            <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label">Name<span style={{ 'color': '#EE1D42' }}>*</span></label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder='Type your name' aria-describedby="emailHelp" required autoComplete="off" />
                                <SVG src={user_circle} className="frm_cntrl_icon" />
                            </div>
                            <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label" >Email<span style={{ 'color': '#EE1D42' }}>*</span></label>
                                <input type="email" className="form-control" id="exampleInputEmail1" placeholder='Enter your Email' aria-describedby="emailHelp" required autoComplete="off" />
                                <SVG src={sms_icon} className="frm_cntrl_icon" />
                            </div>
                            <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label">Phone No<span style={{ 'color': '#EE1D42' }}>*</span></label>
                                <input type="text" className="form-control" id="exampleInputEmail1" placeholder='+ 91' aria-describedby="emailHelp" required autoComplete="off" />
                                <SVG src={phone_icon} className="frm_cntrl_icon" />
                            </div>
                            <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label">Select</label>
                                <select type="dropdown" className="form-select" select="exampleInputEmail1" aria-describedby="emailHelp" autoComplete="off">
                                    <option> Select Here </option>
                                </select>
                            </div>
                            <div className="mb-3" style={{ position: 'relative' }}>
                                <label htmlFor="exampleInputEmail1" className="form-label">Comment</label>
                                <textarea rows={4} className="form-control" id="exampleInputEmail1" placeholder='Type your comment here' aria-describedby="emailHelp" autoComplete="off" />
                            </div>

                            <div className='mb-3 mt10 d-flex justify-content-center'>
                                <button type="button" className="submit_button w45">
                                    <SVG src={plane_icon} style={{ marginRight: '8%' }} />
                                    Submit Now
                                </button>
                            </div>

                        </form>

                    </div>
                </div> */}

                {/* <Support/> */}

            </div>
            <Office/>
            <Contact/>
            <Footer />
        </div>
    )
}

export default ContactUsPage;