import React, { useEffect, useState,} from "react";
import PropTypes from 'prop-types'
import SVG from "react-inlinesvg";
import user_circle from "../Assets/Images/user_circle.svg"
import sms_icon from "../Assets/Images/sms.svg"
import phone_icon from "../Assets/Images/phone.svg"
import plane_icon from "../Assets/Images/plane.svg"
import { UserProfile } from "../Context/UserContext.js";
import Config from "../Config/Config.json";
import { useNavigate } from "react-router-dom";

const Quote = () => {
  const navigate = useNavigate();
  const {addQuote, allCategory} = UserProfile()
  const [quoteName, setQuoteName] = useState('')
  const [quoteEmail, setQuoteEmail] = useState('')
  const [quotephNo, setQuotePhNo] = useState('')
  const [quoteDescription, setQuoteDescription] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [catData, setCatData] = useState([])

  const [quoteNameError, setQuoteNameError] = useState(false)
  const [quoteEmailError, setQuoteEmailError] = useState(false)
  const [quotePhNoError, setQuotePhNoError] = useState(false)
  const [quoteDescError, setQuoteDescError] = useState(false)
  
  useEffect(()=>{
    
    // setCatData(allCategory.data);
    if (allCategory === undefined || allCategory === '') {
      setCatData([])
  }
  else {
    setCatData(allCategory.data)
  }
    // console.log("CatData", allCategory);
  }, [allCategory])


  const quoteNameHandler=(e)=>{
    setQuoteName(e.target.value)
  }

  const quoteEmailHandler=(e)=>{
    setQuoteEmail(e.target.value)
  }

  const quotephNoHandler=(e)=>{
    setQuotePhNo(e.target.value)
  }
  const quoteCatIDHandler=(e)=>{
    setCategoryId(e.target.value)
  }
  const quoteDescriptionHandler=(e)=>{
    setQuoteDescription(e.target.value)
  }
  

  const saveQuote=async()=>{
    if(quoteName === '' || quotephNo === '' || categoryId === 0 || quoteDescription === '')
    {
      setQuoteNameError(true)
      setQuotePhNoError (true)
      setQuoteDescError(true)
      // setCategoryId ('')
      // setQuoteDescription('')
    }
    else if(quoteName === '')
    {
      setQuoteNameError (true)
      setQuotePhNoError (false)
      setQuoteDescError(false)
    }
    
    else if(quotephNo === '')
    {
      setQuoteNameError (false)
      setQuotePhNoError (true)
      setQuoteDescError(false)
    }
    else if(quoteDescription === '')
    {
      setQuoteNameError (false)
      setQuotePhNoError (false)
      setQuoteDescError(true)
    }
    // else if(categoryId === 0)
    // {
    //   setCategoryId ('Please Select Category')
    // }
     else{
      var sendData = {
        "name": quoteName,
        "email": quoteEmail,
        "ph_no": parseInt(quotephNo),
        "category_id": parseInt(categoryId),
        "description": quoteDescription
    }
      console.log(JSON.stringify(sendData))
      const resp = await addQuote(sendData)
      console.log("RESP",resp)
      setQuoteName('')
      setQuoteEmail('')
      setQuotePhNo('')
      setCategoryId(0)
      setQuoteDescription('')
      
        navigate('/thank-you');
      
    }
  }
    
    return (
      <div className='form_quote'>
          <div className='form_header'>Get A Quote</div>

          <div className='form_text_class'>
            <div class_name='form_text'>Fill the form below and we will reply with a </div>
            <div>custom Quote for your needs</div>
          </div>

          <div className='form_field_class'>
            <form>
              <div className="field_holder_frm">
              <div className="mb-3 w33" style={{ position: 'relative' }}>
                <label htmlFor="exampleInputEmail1" className="form-label">Name<span style={{ 'color': '#EE1D42' }}>*</span></label>
                <input type="text" 
                  className="form-control"
                  placeholder='Type your name' 
                  required autoComplete="off"
                  value={quoteName} 
                  onChange={quoteNameHandler} 
                  style={{border:quoteNameError===false?'1px solid #ced4da':'1px solid red'}}
                  />
                <SVG src={user_circle} className="frm_cntrl_icon" />
              </div>
              <div className="mb-3 w33" style={{ position: 'relative' }}>
                <label htmlFor="exampleInputEmail1" className="form-label" >Email</label>
                <input type="text" 
                  className="form-control" 
                  id="exampleInputEmail" 
                  placeholder='Enter your Email'
                   autoComplete="off" 
                  value={quoteEmail} 
                  onChange={quoteEmailHandler}
                  style={{border:'1px solid #ced4da'}}
                  />
                <SVG src={sms_icon} className="frm_cntrl_icon" />
              </div>
              <div className="mb-3 w33" style={{ position: 'relative' }}>
                <label htmlFor="exampleInputEmail1" className="form-label">Phone No<span style={{ 'color': '#EE1D42' }}>*</span></label>
                <input 
                  type="text" 
                  className="form-control"
                  placeholder='Enter your phone number'
                  autoComplete="off" 
                value={quotephNo} 
                onChange={quotephNoHandler}
                style={{border:quotePhNoError===false?'1px solid #ced4da':'1px solid red'}}
                />
                <SVG src={phone_icon} className="frm_cntrl_icon" />
              </div>
              </div>
              <div className="field_holder_frm">
                <div className="mb-3 w33" style={{ position: 'relative'}}>
                  <label htmlFor="exampleInputEmail1" className="form-label">Select</label>
                  <select type="dropdown" className="form-select" autoComplete="off"
                  onChange={(e)=>quoteCatIDHandler(e)} value={categoryId}>
                    <option  value={0}> Select Here </option>
                    {catData.map((data,index)=>(
                      <option value={data.id} key={index}>
                        {data.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3 w70" style={{ position: 'relative' }}>
                  <label htmlFor="exampleInputEmail1" className="form-label">Comment</label>
                  <textarea 
                  rows={1} 
                  className="form-control" 
                  placeholder='Type your comment here' 
                  autoComplete="off" 
                  value={quoteDescription} 
                  onChange={quoteDescriptionHandler}
                  style={{border:quoteDescError===false?'1px solid #ced4da':'1px solid red'}}
                  />
                </div>
                {/* <div className="mb-3 w33"></div> */}
              </div>
              <div className='mb-3 d-flex justify-content-center'>
                {/* <button type="button" className="submit_button w45" onClick={saveQuote}>
                  <SVG src={plane_icon} />
                    Submit Now
                </button> */}
                <button type="button" className="submit_button btn" onClick={saveQuote}>
                  <SVG src={plane_icon}/>
                    <span className="ml-5 pl-5">Submit Now</span>
                </button>
              </div>
              

            </form>

          </div>

        </div>
        
    );
}


export default Quote;


Quote.protoTypes = {
  title: PropTypes.string.isRequired,    
}